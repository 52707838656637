import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

export default class BcQueuesProvider extends HttpRequest {
  getBcQueues (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/bc-queues', query)
  }

  updateBcQueueById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/bc-queues/${payload.id}`, payload)
  }

  manualBcQueueById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/bc-queues/${payload}/manual`)
  }
}
