<template>
  <v-container
    fluid>
    <v-row>
      <v-col
        cols="12"
        :md="selectItem && showDetail ? '8' : '12'"
        class="table-detail">
        <v-data-table
          :headers="header"
          :items="items"
          :options.sync="options"
          :items-per-page="options.itemsPerPage"
          :server-items-length="total"
          :loading="loading"
          :search="inputSearch"
          :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
          class="w-full"
          @update:options="getData()"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <span class="capitalize"> BC Queues </span>
              </v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical />
              <v-row justify="end">
                <v-col cols="4">
                  <v-select
                    v-model="filterStatus"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="สถานะ"
                    color="secondary"
                    outlined
                    dense
                    hide-details>
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <search-box
                    v-model="inputSearch"
                    @on-search="getData(true)" />
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <template #[`item.updatedAt`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.updatedAt | dateTimeUserFormat() }}
            </span>
          </template>
          <template #[`item.createdAt`]="{ item }">
            <span style="white-space: nowrap;">
              {{ item.createdAt | dateTimeUserFormat() }}
            </span>
          </template>
          <template #[`item.description`]="{ item }">
            <div style="display: flex; flex-direction: row; align-items: center; gap: 0.2rem;">
              <v-icon :color="getStatusStyle(item.status).color">
                {{ getStatusStyle(item.status).icon }}
              </v-icon>
              <span class="limit-text">
                {{ item.description }}
              </span>
            </div>
          </template>
          <template #[`item.option`]="{ item }">
            <v-btn
              link
              icon
              x-small
              :disabled="isShowButton(item) && (item.status === 'forcing' || item.status === 'running')"
              @click="onConfirmForceRun(item)">
              <v-icon>
                mdi-play-outline
              </v-icon>
            </v-btn>
            <v-btn
              link
              icon
              x-small
              :disabled="isShowButton(item)"
              @click="editItem(item)">
              <v-icon>
                mdi-pencil-outline
              </v-icon>
            </v-btn>
            <v-btn
              link
              icon
              x-small
              :disabled="showDetail && selectItem && selectItem.id === item.id"
              @click="showItemDetail(item)">
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="selectItem && showDetail"
        cols="12"
        md="4"
        class="pa-0">
        <div class="log-detail">
          <div style="display: flex; flex-direction: row; align-items: center; gap: 0.2rem;">
            <v-icon :color="getStatusStyle(selectItem.status).color">
              {{ getStatusStyle(selectItem.status).icon }}
            </v-icon>
            <h3 class="text-bold mb-0">
              {{ selectItem.description }}
            </h3>
          </div>
          <!-- <p class="mb-0 mt-2">
            <span
              :style="getStatusStyle(selectItem.httpStatus)"
              class="text-bold">{{ selectItem.httpStatus }}</span> {{ selectItem.statusText }}
          </p> -->
          <p class="mb-0">
            <b>Date </b>{{ selectItem.createdAt | dateTimeUserFormat() }}
          </p>
          <p class="mb-0">
            <b>Last update </b>{{ selectItem.updatedAt | dateTimeUserFormat() }}
          </p>
          <p class="mb-0">
            <b>Document No. </b> {{ selectItem.documentNo }}
          </p>
          <div class="mt-4">
            <a @click="scrollTo('Config')">#Config</a>
            <a
              class="ml-4"
              @click="scrollTo('Body')">#Body</a>
            <a
              class="ml-4"
              @click="scrollTo('Response')">#Response</a>
          </div>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="{ method: selectItem.method, baseUrl: selectItem.baseUrl }"
              :expand-depth="1"
              copyable />
          </div>
          <p
            ref="Config"
            class="mt-4">
            Config
          </p>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="selectItem.config"
              :expand-depth="1"
              copyable />
          </div>
          <p
            ref="Body"
            class="mt-4">
            Body
          </p>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="selectItem.body"
              :expand-depth="5"
              copyable
              sort />
          </div>
          <p
            ref="Response"
            class="mt-4">
            Response
          </p>
          <div style="border: 1px solid #000;">
            <json-viewer
              :value="selectItem.result"
              :expand-depth="5"
              copyable
              sort />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showEdit"
      persistent
      max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">BC Queue Form</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="bc-queue-form"
              @submit.prevent="onSubmit()">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="formData.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    color="secondary"
                    label="สถานะ"
                    outlined
                    dense
                    hide-details>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            :loading="loading"
            @click="closeForm()">
            Close
          </v-btn>
          <v-btn
            type="submit"
            color="green darken-1"
            text
            :loading="loading"
            @click="onSubmit()">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import BCQueuesProvider from '@/resources/BCQueuesProvider'

const BcQueuesService = new BCQueuesProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      inputSearch: '',
      filterStatus: null,
      status: [
        {
          text: 'All',
          value: null,
          color: 'warning',
          icon: 'mdi-help-circle-outline'
        },
        {
          text: 'Pending',
          value: 'pending',
          color: '#bbbbbb',
          icon: 'mdi-clock-outline'
        },
        {
          text: 'Running',
          value: 'running',
          color: 'warning',
          icon: 'mdi-sync'
        },
        {
          text: 'Forcing',
          value: 'forcing',
          color: 'warning',
          icon: 'mdi-sync'
        },
        {
          text: 'Success',
          value: 'success',
          color: 'success',
          icon: 'mdi-check'
        },
        {
          text: 'Failed',
          value: 'failed',
          color: 'error',
          icon: 'mdi-alert-circle-outline'
        },
        {
          text: 'Skipped',
          value: 'skipped',
          color: '#9b23c3',
          icon: 'mdi-debug-step-over'
        }
      ],
      loading: false,
      selectItem: null,
      formData: {
        id: null,
        status: 'pending'
      },
      showDetail: false,
      showEdit: false,
      total: 0,
      pageCount: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['createdAt'],
        sortDesc: [false],
        status: null
      },
      header: [
        {
          text: 'Last update',
          value: 'updatedAt'
        },
        {
          text: 'Date',
          value: 'createdAt'
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false
        },
        {
          text: 'Document No',
          value: 'documentNo',
          sortable: false
        },
        {
          text: 'Options',
          value: 'option',
          align: 'center',
          sortable: false,
          width: '100px'
        }
      ],
      items: []
    }
  },
  watch: {
    filterStatus: {
      handler () {
        this.getData(true)
      },
      deep: true
    }
  },
  created () {
    this.getData(true)
  },
  methods: {
    ...mapActions({
      setModal: 'Components/setModal'
    }),
    showItemDetail (item) {
      this.selectItem = item
      this.showDetail = true
      this.showEdit = false
    },
    onConfirmForceRun (item) {
      this.setModal({
        value: true,
        title: 'Force Run',
        message: 'Do you want to force run this document',
        confirmText: 'Sure',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.forceRun(item)
      })
    },
    async forceRun (item) {
      try {
        this.loading = true

        await BcQueuesService.manualBcQueueById(item.id)

        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: 'Update queue success.',
          type: 'success'
        })

        this.getData(true)
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    editItem (item) {
      this.selectItem = item
      this.formData = { ...item }
      this.showDetail = false
      this.showEdit = true
    },
    closeForm () {
      this.selectItem = null
      this.showEdit = false
    },
    scrollTo (tag) {
      const el = this.$refs[tag]
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
    getStatusStyle (status) {
      const found = this.status.find((s) => s.value === status)

      return {
        color: found?.color || 'warning',
        icon: found?.icon || 'mdi-help-circle-outline'
      }
    },
    async getData (clear = false) {
      this.showDetail = false

      if (clear) {
        this.options.page = 1
      }

      try {
        this.loading = true
        const { data: { results, total } } = await BcQueuesService.getBcQueues(
          {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc',
            status: this.filterStatus,
            search: this.inputSearch
          }
        )
        this.items = results
        this.total = total
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true

        await BcQueuesService.updateBcQueueById(this.formData)

        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: 'Update queue success.',
          type: 'success'
        })

        this.closeForm()
        this.getData(true)
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    isShowButton (item) {
      return this.showEdit && this.selectItem && this.selectItem.id === item.id
    }
  }
}
</script>
<style scoped>
.table-detail {
  height: calc(100vh - 80px);
  overflow-y: auto;
  background-color: #fff;
}
.log-detail {
  width: 100%;
  margin-left: 10px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  height: calc(100vh - 80px);
  background-color: #fff;
}
</style>
